<template>
  <div class="page" v-if="isShow">
		<!-- 下载 -->
		<download-bar class="download-bar"></download-bar>

    <!-- 商品视频图片 -->
		<div class="goods-swipe" ref="GoodsSwipe" width="100%">
			<template v-if="goods_images.length>0">
				<van-swipe :autoplay="5000" ref="Banner" indicator-color="#fff">
					<van-swipe-item v-for="(item, index) in goods_images" :key="index" @click="imgPreview(index);">
						<img :style="{width: '100%'}" :src="item">
					</van-swipe-item>
				</van-swipe>
			</template>
			<template v-else>
				<div class="image_blank"></div>
			</template>
    </div>

    <!-- 商品基本信息 -->
    <div class="info_zone">
      <div class="goodsInfo">
				<div class="i-row show_text_1">
					<div>
						<span class="show_price_active">
							<template v-if="max_price==0||max_price==zk_final_price_wap">
								<span class="sym">¥</span>
								<span class="price b">{{zk_final_price_wap}}</span>
							</template>
							<template v-else>
								<span class="sym">¥</span>
								<span class="price b">{{zk_final_price_wap}}</span>
								-
								<span class="sym">¥</span>
								<span class="price b">{{max_price}}</span>
							</template>
						</span>
						<span class="original_price">
							<del>原价￥{{parseFloat(reserve_price)}}</del>
						</span>
					</div>
					<div class="fc07">
						<span class="f12">月销量</span>
            <span class="f12">{{volume}}</span>
					</div>
				</div>
        <div class="i-row show_text_2" justify="left">
          <div class="price_tip">淘街坊价</div>
          <div class="fc07">
            <span class="f12" v-if="!!provcity">{{provcity}}</span>
          </div>
        </div>
				<!-- 商品标题 -->
				<div class="title">
					<span class="">{{goods_title}}</span>
				</div>
				<!-- 优惠券 -->
				<div class="coupon_box" @click="getTBKLink" v-if="!!coupon_amount">
					<div class="coupon_inner" :style="couponStyle">
						<div class="coupon_left">
							<div class="coupon_amount f14 fcff tc b">
								<span class="f18">{{coupon_amount}}</span> 元优惠券
							</div>
							<div class="coupon_expire f12 fcff tc">有效期：{{coupon_start_time}}-{{coupon_end_time}}</div>
						</div>
						<div class="coupon_right">
							<span class="f14 fcff">立即领券</span>
						</div>
					</div>
				</div>
      </div>
    </div>
    <!-- 商品详情 -->
    <div class="mb20">
      <goods-content :content="goods_content" :detail_images="detail_images"></goods-content>
    </div>

    <!-- 优惠券弹窗 -->
    <div class="popup-box">
      <van-popup v-model="dialogshow">
        <div class="dialog-content">
          <div class="show-image-bar">
            <div class="show-image">
              <img :src="pict_url" alt>
            </div>
          </div>
          <div class="content-box">
            <div class="text-box">
              复制框内整段文字，打开【淘宝APP】即可
              <span v-if="!!coupon_amount">领券</span>购买。
              <span>{{ _getTaoToken }}</span>
            </div>
            <template v-if="is_copy">
              <div class="copy_btn copy_done">复制成功</div>
            </template>
            <template v-else>
              <div class="copy_btn" @success="_copyTaoTokenSuccess" v-clipboard="_getTaoToken">一键复制</div>
            </template>
          </div>
        </div>
      </van-popup>
    </div>

    <!-- 底部按钮 -->
		<div class="bottom_box">
			<div class="buttons">
				<div class="buttons_item b_only_one" @click="getTBKLink">
					<span>购买</span>
				</div>
			</div>
		</div>

  </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview, Row, Col, Sku, Popup, Button, Lazyload, Swipe, SwipeItem, Loading } from "vant";
import StatusBar from "@/components/StatusBar";
import GoodsContent from "./components/GoodsContent";
import ls from "@/libs/localstore";
import { canInviteFriends } from "@/api/qianggou";
import { getShareItem as getProduct, getDetailContent } from "@/api/product";
import VueClipboards from "vue-clipboards";
import ShowCustomerQrcode from "@/components/customer/Show";
import ReviewBar from "./components/ReviewBar";
import { isMicroMessenger } from '@/utils/user-agent'
import QRCode from 'qrcode'
import html2canvas from 'html2canvas'
import DownloadBar from '@/components/DownloadBar'

import "@/utils/ap";

Vue.use(ImagePreview)
  .use(Row)
  .use(Col)
  .use(Sku)
  .use(Popup)
  .use(VueClipboards)
	.use(Button)
	.use(Swipe)
	.use(SwipeItem)
	.use(Loading)
	.use(Lazyload);

export default {
  name: "GoodsItem",
  components: {
    StatusBar,
    GoodsContent,
    ShowCustomerQrcode,
		ReviewBar,
		DownloadBar
  },
  data() {
    return {
			nickname: '',
			avatar: '',
			qrcode: '',
			is_generate_done: false,
			showShare:false,
			is_shot:false,
			hide_btn:false,
			recommend_list: [],
			detail_images: [],
      item_id: 0,
      dialogshow: false,
      is_copy: false,
      showSku: false,
      cartCount: 0,
      item_data: {},
      isShow: true,
      isShowBackBtn: true,
      player: null,
      user_amount: 0,
      user_price: 1,
      img: {
        back_btn: require("@/assets/common/back_btn_circle.png")
      },
      couponStyle: {
        backgroundImage:
          "url(http://img.quanminhuyu.com/FkZzH437Kks9wJ_gNwq5jFd1Rlb1?imageMogr2/thumbnail/400x)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "100% 100%"
      },
      product_id: 0,
      goods_id: 0,
      goods_url: "",
			goods_video: "",
			provcity: '',
      goods_title: "",
      pict_url: "",
      goods_content: "",
      goods_images: [],
      goods_type: 1,
      reserve_price: 0,
      zk_final_price_wap: 0,
      max_price: 0,
      volume: 0,
      limit: 0,
      redpacket_quantity: 0,
      redpacket_amount: "",
      couponLinkTaoToken: "",
      taoToken: "",
      coupon_start_time: "",
      coupon_end_time: "",
      coupon_amount: "",
      coupon_click_url: "",
			is_deductible: false,
			is_new_user: false,
      can_buy: false,
      action_at: "",
      reason: "",
      can_invite: false,
      customerInfo: {},
      sku: {
        tree: [],
        list: [],
        price: 0,
        stock_num: 0,
        collection_id: 2261,
        collection_price: 0,
        none_sku: false,
        sold_num: 0,
        min_price: "1.00",
        max_price: "1.00",
        messages: []
      },
      step_number: 1,
			platform: 0,
      deduction: 0,
			review_data: {},
			user_commission: 0,
			user_score: 0,
			user_tnl: 0,
      customStepperConfig: {
        stockFormatter: stock => `剩余${stock}件`,
        handleOverLimit: data => {
          const { action, limitType, quota } = data;
          if (action === "minus") {
            this.$toast("至少选择一件商品");
          } else if (action === "plus") {
            this.$toast(`限购${quota}件`);
          }
        }
      }
    };
  },
  created() {},
  computed: {
    _getTaoToken() {
      if (this.couponLinkTaoToken) {
        return this.couponLinkTaoToken;
      }

      return this.taoToken;
    }
	},
	
	watch: {
		'$route' (to, from) { //监听路由是否变化
			if(to.query.id != from.query.id){
				this.product_id = to.query.id
				this.isShow=false
				// this._initCartCount()
				let that = this;
				setTimeout(function() {
					that._loadData();
				}, 200);
				this.isShow=true
			}
		}
	},
  mounted() {
		this.isShow=false
    // this._initCartCount()
    let that = this;
    setTimeout(function() {
			that._loadData()
		}, 200);
		this.isShow=true
  },
  methods: {
		_closeMask() {
			this.showShare=false
		},
    onClickCustomer(mes) {
      this.$refs.ShowQrcode.show();
    },
    _cantBuy() {
      this.$toast(this.reason);
    },
    _copyTaoTokenSuccess() {
      this.is_copy = true;
    },
    _loadData() {
			let goods_id = this.$route.query.id
      let params = {
				p: this.$route.query.p,
				pid: this.$route.query.pid,
				goods_id: goods_id
			};
			
			this.$toast.loading({
				duration: 0,
				loadingType: 'spinner',
				message: '加载中...',
				mask: false,
				forbidClick: true
			})
      getProduct(params).then(res => {
        if (res.errCode == 0) {
					this.item_data = res.data;
					this.nickname = res.data.nickname;
					this.avatar = res.data.avatar;
          this.goods_title = res.data.goods_title;
          this.goods_content = res.data.detail;
          this.goods_video = res.data.goods_video;
          this.reserve_price = res.data.reserve_price;
          this.goods_url = res.data.click_url;
          this.pict_url = res.data.pict_url;
          this.goods_type = parseInt(res.data.type);
					this.sku.price = res.data.price;
					this.provcity = res.data.provcity
          if (res.data.goods_images.length >= 5) {
            res.data.goods_images.pop();
          }
          this.product_id = res.data.goods_id;
          this.goods_images = res.data.goods_images;
          this.volume = res.data.volume;
          this.zk_final_price_wap = parseFloat(res.data.price);
          this.max_price = parseFloat(res.data.max_price);
          this.taoToken = res.data.taoToken;
          this.couponLinkTaoToken = res.data.couponLinkTaoToken;
          this.coupon_start_time = res.data.coupon_start_time;
          this.coupon_end_time = res.data.coupon_end_time;
          this.coupon_amount = res.data.coupon_amount;
          this.coupon_click_url = res.data.coupon_click_url;
					this.is_deductible = res.data.is_deductible;
					this.is_new_user = res.data.is_new_user
          this.action_at = res.data.action_at;
          this.can_buy = res.data.can_buy;
          this.reason = res.data.reason;
					this.platform = res.data.platform
					this.deduction = parseFloat(res.data.deduction);
					this.user_commission = res.data.user_commission
					this.user_score = res.data.user_score
					this.user_tnl = res.data.user_tnl
        }
				this.$toast.clear();
				this._getDetailContent()
      }).catch(err => {
				this.$toast.clear();
			});
		},
		_getDetailContent() {
			let id = this.$route.query.id
			let params = {
				 
				goods_id: id
			}
			getDetailContent(params).then(res => {
				if(res.errCode === 0) {
					if(res.data.type == 'images') {
						this.detail_images = res.data.data
					}else{
						this.goods_content = res.data.data
					}
				}
			})
		},
    imgPreview(index) {
      this.isShowBackBtn = false;
      let that = this;
      ImagePreview({
        images: this.goods_images,
				startPosition: index,
				closeOnPopstate: true,
        onClose() {
          // do something
          that.isShowBackBtn = true;
        }
      });
		},
    // 淘宝商品跳转
    getTBKLink() {
			if ((this.platform==1 || this.platform==2) && isMicroMessenger) {
				if(this.couponLinkTaoToken || this.taoToken) {
					this.dialogshow = true;
					this.is_copy = false;
				}
			}else{
				window.location.href = this.coupon_click_url || this.goods_url;
			}
    }
  }
};
</script>

<style scoped>
.page {
	margin-top: 60px;
  padding-bottom: 70px;
  text-align: left;
	position: relative;
	background: #F8F8F8;
	z-index: 1;
}
#back_btn {
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 99999;
}
.image_blank {
	height: 300px;
	background: #f0f0f0;
}
.van-swipe-item {
	font-size: 0px;
}
.goods-swipe {
	position: relative;
	width: 100%;
	z-index: 0;
}
.goods-tips {
	width: 100%;
	height: 40px;
	font-family: 'PingFangSC-Medium';
	font-size: 10px;
	color: #FFFFFF;
	letter-spacing: 1px;
	background: linear-gradient(90deg, #D6ADFF  0%, #FF81A7 100%);
	text-align: center;
	line-height: 40px;
}
.title {
	font-family: 'PingFangSC-Regular';
	font-size: 12px;
	color: #4D4D4D;
	letter-spacing: 1px;
  text-align: left;
	margin-bottom: 10px !important;
}
.show_text_1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.show_price_active {
  color: #f40337;
	margin-right: 5px;
}
.original_price {
	font-size: 10px;
	color: #9B9B9B;
}
.price {
  font-size: 18px;
}
.sym {
  font-size: 12px;
}
.goodsInfo {
  width: 326px;
	margin: 15px auto;
}
.buynow {
  background: #f8f8f8;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding-top: 10px;
}
.show_text_2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.price_tip {
	background-image: linear-gradient(141deg, #82BEFF 0%, #F093FF 100%);
	border-radius: 46px;
	color: #fff;
	font-size:12px;
	width: 70px;
	height: 20px;
	line-height: 20px;
	text-align: center;
}
.info_zone {
  position: relative;
  background: #fff;
	border-bottom: 1px solid #F2F2F2;
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  #back_btn {
    top: 42px;
  }
}

.sku_box /deep/ .van-sku-row__item--active {
  border-color: #FF7C8A;
  background: #FF7C8A;
}

.page /deep/ .van-sku-actions .van-button--danger {
  border-color: #FF7C8A;
  background: #FF7C8A;
}

.page /deep/ .van-sku-actions .van-button--warning {
  border-color: #FFCD7C;
  background: #FFCD7C;
}

.goods_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goods_pic {
  height: 50px;
  margin-right: 10px;
}

.goods_title {
  font-size: 12px;
  color: #030303;
  letter-spacing: 0;
  text-align: left;
  line-height: 18px;
}

.cart_price {
  font-size: 10px;
  color: #f40337;
}

.sku_title {
  font-size: 10px;
  color: #f40337;
}

.popup-box {
  position: relative;
}
.popup-box .van-popup {
  overflow: visible;
  background: none;
}

.dialog-content {
  min-width: 300px;
  position: relative;
}

.show-image-bar {
  width: 100%;
  position: absolute;
  top: -50px;
  left: 0;
  display: flex;
  justify-content: center;
}

.show-image {
  width: 100px;
  height: 100px;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
}

.content-box {
  border-radius: 5px;
  padding: 60px 10px 20px;
  background: #fff;
  overflow: hidden;
}

.text-box {
  padding: 10px;
  background: #f1f1f1;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 13px;
}

.show-image img {
  width: 100%;
}

.copy_btn {
  width: 100%;
  height: 40px;
  text-align: center;
  color: #fff;
  background: #f40337;
  border-radius: 100px;
  font-size: 16px;
  line-height: 40px;
}

.copy_done {
  background: #1fb931;
}

.coupon_box {
  padding: 0;
}

.coupon_inner {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupon_left {
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.coupon_right {
  width: 100px;
  text-align: right;
  padding-right: 10px;
}

.mainPics {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.pic_item {
  width: 60px;
  height: 60px;
  padding: 5px;
	overflow: hidden;
}

.pic_item img {
  width: 100%;
}

.sku_box /deep/ .van-sku-row__item {
  height: auto;
  text-align: left;
}

.van-goods-action-mini-btn {
  min-width: 20%;
}

.van-goods-action /deep/ .van-button--warning {
  background-color: #ef6f8b;
  border: 1px solid #ef6f8b;
}

.dk_tips {
  padding: 15px 26px;
  background: #fff;
  margin-bottom: 10px;
}

.i-row {
	margin-bottom: 10px !important;
}

.bottom_box {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
  background: #fff;
	padding: 5px 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #F2F2F2;
}

.tools {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tools_item {
	width: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	color: #4D4D4D;
	letter-spacing: 0.83px;
}

.tools_icon {
	width: 25px;
	height: 25px;
}

.tools_text {
	line-height: 13px;
	font-size: 10px;
	-webkit-transform:scale(0.8);
}

.buttons {
	display: flex;
	justify-content: flex-start;
	width: 280px;
}

.buttons_item {
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #FFCD7C;
	color: #fff;
	font-size: 12px;
	line-height: 14px;
}

.b_left {
	width: 50%;
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
	background: #FFCD7C;
}

.b_left:active {
	background: rgb(241, 190, 108);
}

.b_right {
	width: 50%;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	background: #FF7C8A;
}

.b_right:active {
	background: rgb(240, 107, 120);
}

.b_only_one {
	width: 100%;
	border-radius: 100px;
	background: #FF7C8A;
}

.b_only_one:active {
	background: rgb(240, 107, 120);
}

.mask {
	width: 100%;
	height: 100%;
	position: fixed;
	top:0;
	left:0;
	background: rgba(0,0,0,.7);
	z-index: 1000;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.mask .popup {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 10px;
}

.post {
	width: 322px;
	/* height: 476px; */
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	overflow: hidden;
	position: relative;
	z-index: 1000;
	top:0;
	left: 0;
}

.post_bg {
	position: relative;
	width: 100%;
	min-height: 200px;
	font-size: 0;
	background: #FFF5F5;
}

.post_bg>img {
	width: 100%;
}

.post_content {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.generate_picture_box {
	width: 322px;
	font-size: 0;
}
.generate_picture_box img {
	width: 100%;
}

.goods_content {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center ;
	flex-direction: column;
	position: relative;
	padding: 72px 0 50px;
}

.change_goods_btn {
	position: absolute;
	right: 30px;
	top: 43px;
	font-family: 'PingFangSC-Regular';
	font-size: 12px;
	line-height: 20px;
	height: 20px;
	padding: 0px 8px;
	color: #FFFFFF;
	letter-spacing: 0;
	background-image: linear-gradient(142deg, #FF7A66 0%, #FF5A98 100%);
	box-shadow: 0 2px 4px 0 rgba(255,160,160,0.50);
	border-radius: 18px;
}

.white-background {
	background: #fff;
	box-shadow: none;
}

.post-logo {
	width: 150px;
	margin: 8px 0 0 10px;
}

.post-logo img {
	width: 100%;
}

.goods_image {
	width: 200px;
	height:185px;
	display: flex;
	justify-content: center;
	overflow: hidden;
	align-items: center;
	font-size: 0;
	margin-bottom: 20px;
}

.goods_image img {
	width: 180px;
}


.goods_title {
	width: 200px;
	height: 35px;
	font-family: 'PingFangSC-Regular';
	font-size: 12px;
	color: #666666;
	letter-spacing: 0;
	text-align: center;
	overflow: hidden;
}

.user-bar {
	padding: 9px 6px;
	width: 281px;
	margin: 0 auto 20px;
	border-radius: 11px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 1;
}

.avatar {
	width: 44px;
	height: 44px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 10px;
	flex: none;
}

.avatar img {
	width: 100%;
}

.user-text {
	height: 50px;
	flex: 1;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.text-default {
	color: #666666;
	font-family: 'PingFangSC-Regular';
	font-size: 10px;
}

.nickname {
	font-family: 'PingFangSC-Medium';
	font-size: 12px;
	color: #666666;
	letter-spacing: 0;
}

.desc {
	font-family:'PingFangSC-Regular';
	font-size: 12px;
	color: #666666;
	letter-spacing: 0;
}

.desc span {
	font-family: 'PingFangSC-Medium';
	font-size: 12px;
	color: #CE244E;
	letter-spacing: 0;
	line-height: 17px;
}

.qrcode {
	width: 50px;
	height: 50px;
	flex: none;
	margin-left: 3px;
}

.qrcode img {
	width: 100%;
}

.generate_post, .confirm_post {
	width: 116px;
	height: 36px;
	font-family: 'PingFangSC-Medium';
	font-size: 16px;
	color: #FFFFFF;
	letter-spacing: 0;
	background-image: linear-gradient(142deg, #FFB865 0%, #FF5A98 100%);
	box-shadow: inset 2px -1px 3px 0 rgba(255,255,255,0.50);
	border-radius: 18px;
	text-align: center;
	line-height: 36px;
}

.show_save_text {
	width: 100%;
	text-align: center;
	font-family: 'PingFangSC-Medium';
	font-size: 16px;
	color: #FFFFFF;
	letter-spacing: 0;
	line-height: 36px;
	margin-bottom: 10px;
}

.post_tips {
	text-align: center;
	margin-bottom: 20px;
	font-family: 'PingFangSC-Regular';
	font-size: 12px;
	color: #666666;
	letter-spacing: 0;
}

.font-pink {
	color: #E4004B ;
	font-size: 12px;
}
.download-bar {
	position: fixed;
	top: 0;
	z-index: 10;
}

</style>
