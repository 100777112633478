<template>
	<div class="container">
		<div class="wrap">
			<div class="left-bar">
				<img class="logo_pic" src="@/assets/download/logo.png">
				<div class="desc">
					<img class="logo_text" src="@/assets/download/logo_name.png" />
					<div class="desc_text">一个帮你省钱的返利app</div>
					<div class="stars-box">
						<img v-for="i in 5" :key="i" class="star_pic" src="@/assets/download/star.png" />
					</div>
				</div>
			</div>
			<div class="right-bar" @click="_goto">
				<span>立即下载</span>
			</div>
		</div>
	</div>
</template>

<script>
import {isAndroid, isIOS} from '@/utils/user-agent'

export default {
	name: 'DownloadBar',
	components:{},
	data(){
		return {
			
		}
	},
	computed:{},
	created(){},
	mounted(){},
	methods:{
		_goto() {
			if(isIOS) {
				window.location.href = 'https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745'
			}else{
				window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang'
			}
		}
	}
}
</script>

<style scoped>
.container {
	width: 100%;
}
.wrap {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content:space-between;
	background: #fff;
}
.right-bar {
	flex: none;
	width: 100px;
	height: 100%;
	background: #FF9BB3;
	color: #fff;
	display:flex;
	justify-content:center;
	align-items:center;
}
.right-bar span {
	font-family: 'PingFangSC-Regular';
	font-size: 14px;
	color: #FFFFFF;
}
.left-bar {
	flex: 1;
	height: 100%;
	display:flex;
	align-items: center;
}
.logo_pic {
	margin: 0 12px 0 10px;
	width: 50px;
	height: 50px;
	font-size: 0;
}
.logo_text {
	height: 15px;
	width: auto;
	font-size: 0;
}
.desc_text {
	font-family: 'PingFangSC-Regular';
	font-size: 12px;
	color: #BCBCBC;
	letter-spacing: 0;
	line-height: 12px;
	margin-bottom: 2px;
}
.stars-box {
	display: flex;
}
.star_pic {
	width: 9px;
	height: 9px;
	margin-right: 3px;
	font-size: 0;
}
</style>